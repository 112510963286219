import React from "react";

import Post from "@/templates/guideArticleCard";

const GuideArticleCardPreview = (props) =>
  <Post
    {...props}
    type="guideArticleCard"
  />;

export default GuideArticleCardPreview;
